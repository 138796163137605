var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "MenuGroup" },
    _vm._l(_vm.group, function (item) {
      return _c("div", { key: item.id, staticClass: "group" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
          _c(
            "div",
            { staticClass: "subList" },
            [
              _vm._l(item.childMenus, function (subItem) {
                return [
                  _c(
                    "div",
                    {
                      key: subItem.id,
                      staticClass: "subItem",
                      on: {
                        click: function ($event) {
                          return _vm.subItemClick(subItem)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "subItem-img",
                          style: { background: _vm.maps[subItem.id] },
                        },
                        [_c("img", { attrs: { src: subItem.icon } })]
                      ),
                      _c("div", { staticClass: "subItem-name" }, [
                        _vm._v(_vm._s(subItem.name)),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }