<template>
  <div class="MenuGroup">
    <div class="group" v-for="item in group" :key="item.id">
      <div class="item">
        <div class="name">{{ item.name }}</div>
        <div class="subList">
          <template v-for="subItem in item.childMenus">
            <div class="subItem" :key="subItem.id" @click="subItemClick(subItem)">
              <div class="subItem-img" :style="{ 'background': maps[subItem.id] }">
                <img :src="subItem.icon" />
              </div>
              <div class="subItem-name">{{ subItem.name }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findFirstMenu } from 'common/menuHandler'
import { mapGetters } from 'vuex'

export default {
  name: 'MenuGroup',
  data () {
    return {
      group: [],
      maps: {},
      backgroundImage: ['#F2725E', '#F7B55D', '#3296FA', '#00B853', '#FF943E']
    }
  },
  created () {
    const { pid } = this.$route.query
    const menuList = this.find(this.menuResourceList, pid).childMenus
    const cloneMenuList = _.cloneDeep(menuList)
    const maps = {}
    let index = 0
    const group = []
    if (cloneMenuList && cloneMenuList.length) {
      cloneMenuList.forEach(item => {
        if (item.specialNo !== 'system_menuGroup') {
          if (item && item.childMenus && item.childMenus.length) {
            item.childMenus.splice( item.childMenus.length)
            item.childMenus.forEach(subItem => {
              maps[subItem.id] = this.backgroundImage[index % 5]
              index++
            })
          }
          group.push(item)
        }
      })
    }
    this.maps = maps
    this.group = group
  },
  computed: {
    ...mapGetters('menuStore', {
      menuResourceList: 'getMenuResourceList'
    })
  },
  methods: {
    find (list, id) {
      let item = list.find((item) => {
        return item.id == id // eslint-disable-line
      })
      return item
    },
    navigate (name) {
      this.$router.push({
        name
      })
    },
    subItemClick (subItem) {
      if (subItem.menuType === 1) {
        const menuItem = findFirstMenu(subItem)
        if (menuItem) {
          this.navigate(menuItem.url)
        }
      } else if (subItem.menuType === 2) {
        this.navigate(subItem.url)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.MenuGroup {
  text-align: left;
  overflow: auto;
  margin-bottom: 20px;
  background: #fff;
  .group {
    .item {
      .name {
        padding: 16px 0 0 24px;
        color: #919FB0;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 18px;
      }
      .subList {
        display: flex;
        flex-flow: wrap;
        justify-content: flex-start;
        padding: 0 34px;
        .subItem {
          width: 176px;
          padding: 10px;
          display: flex;
          flex: none;
          align-items: center;
          margin: 0 24px 20px 0;
          border-radius: 8px;
          cursor: pointer;
          &:hover {
            box-shadow:0px 0px 16px 0px rgba(27,140,242,0.15);
          }
          .subItem-img {
            width: 36px;
            height: 36px;
            border-radius: 6px;
            text-align: center;
            margin-right: 10px;
            transition-duration: .3s;
            transition-property: transform;
            img {
              width: 20px;
              height: 20px;
              padding-top: 8px;
            }
          }
          .subItem-name {
            font-size: 14px;
            color: #333333;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
